.pictureBox {
    width: 50px;
    height: 50px;

    border-radius:150px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}